<template>
    <DnModal>
        <!-- Pass down whatever slots are given to this component-->
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope || {}"
            />
        </template>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
</script>

<style src="./BaseModal.less" lang="less" scoped></style>
