<template>
    <BaseModal
        class="sheet"
        transition-name="sheet"
        v-bind="$attrs"
    >
        <template #close-button>
            <div class="sheet__close">
                <BaseButton
                    class="sheet__close-button"
                    @click="$emit('close')"
                >
                    <BaseIcon icon="cross" />
                </BaseButton>
            </div>
        </template>

        <template #default>
            <h3
                v-if="!!$slots.title"
                class="sheet__title"
            >
                <slot name="title" />
            </h3>

            <div
                v-if="!!$slots.subTitle"
                class="sheet__sub-title"
            >
                <slot name="subTitle" />
            </div>

            <div class="sheet__main-content">
                <slot />
            </div>

            <div
                v-if="!!$slots.footer"
                class="sheet__footer"
            >
                <slot name="footer" />
            </div>
        </template>
    </BaseModal>
</template>

<style lang="less" src="./BaseSheet.less" />
